.choose-bullard {
	position: relative;

	&__map {
		height: 100%;
		position: absolute;
			top: 0;
			right: 0;
		width: 85%;

		img { @include object-fit(contain, right top); }
	}

	&__text {
		position: relative;
	}

	&__cta {
		margin-bottom: $spacing;
		padding: $spacing;

		h3 {
			font-weight: 700;
			position: relative;
			text-transform: none;

			&:after {
				@include size(2rem);
				background-position: left;
				background-repeat: no-repeat;
				background-size: 1.5rem;
				content: '';
				position: absolute;
					top: 0;
					left: -3rem;
			}
		}

		&:first-child h3:after {background-image: url('#{$libraryPath}/icons/src/phone-mail.svg');}
		&:nth-child(2) h3:after {background-image: url('#{$libraryPath}/icons/src/safety-expert.svg');}
	}

	@include media($screen-sm-max, 'max') {
		.contain {padding-top: 3rem;}

		&__map {opacity: .4;}

		&__ctas {margin: 0 -1rem;}

		&__cta {
			h3 {
				margin-left: 3rem;
			}
		}
	}

	@include media($screen-sm) {
		&__map {
			width: 75%;

			img {object-position: right center;}
		}

		&__text {
			max-width: 30rem;
			width: 75%;
		}

		&__cta {
			margin-top: $spacing*2;
			padding: #{$spacing*2} #{$spacing*2} #{$spacing*2} #{$spacing*3};

			h3:after {background-size: contain;}
		}
	}

	@include media($screen-md) {
		&__ctas {transform: translateX(-$spacing);}
	}
}