/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */

.site-footer {
	overflow: hidden;
	clear: both;
	margin-top: auto; // Sticky footer

	.contain {
		padding: 0 $spacing;
	}

	// Resets
	ul { list-style: none; margin: 0; padding: 0; }

	// Default link styles
	a {
		text-decoration: none;
		
		&:hover { text-decoration: underline; }
	}

	&__top {
		border-bottom: 1px solid $gray;
		padding: $spacing*2 0;
        
        form .gform_footer .button,
        .subscribe-button {
            appearance: none;
            background: #424B5A !important;
            border: none;
            border-radius: 0 2px 2px 0;
            color: $white;
            font-size: .85rem;
            height: 2.5rem;
            line-height: 1.4;
            margin: 0 !important;
            padding: .75rem 1.5rem;
            text-align: center;
            text-transform: uppercase;
        }
        
        .subscribe-button {
            width: auto;
        }
        
        .gform_wrapper {
            flex: 1;
            margin-left: 0;
        }

		form {
			display: flex;
			margin: 0 auto;
            max-width: 34rem;
			width: 100%;

			.gform-body {
                flex: 1;

				input {
					border: 1px solid $gray;
					border-right: none;
					border-radius: 2px 0 0 2px;
                    flex: 1;
					height: 2.5rem;
					padding: 0 .75rem;
                    width: 100%;

					&::placeholder {color: $gray;}
				}
			}

			.gform_footer {
				width: 9rem;
                
                .button {
                    width: 100%;
                }
			}
		}
	}

	&__center {
		padding: #{$spacing*2} 0;
        
        .contain {
            gap: $spacing;
        }
	}

	.site-footer__logo img {
		display: block;
		max-width: 12em;
		padding-bottom: 1em;
	}

	&__content {
		display: inline-block;
	}

	&__business {
		ul, li {
			display: block;
		}
	}
    
    &__meta {
        font-size: .85rem;
        max-width: 24rem;
		padding-right: $spacing;
    }

	&__navigation {

		.footer-menu__item {
			a {
				display: block;
				font-weight: 400;
				padding: .25rem 0;

				&:after {
					background: $white;
					content: '';
					display: block;
					height: 1px;
					position: relative;
						top: 3px;
					transform: scaleX(0);
					transform-origin: 100% 0;
					transition: transform .25s ease-in-out;
				}
	
				&:hover,
				&:focus {
					text-decoration: none;

					&:after {
						transform: scaleX(1);
						transform-origin: 0 100%;
					}
				}
			}

			&.toplevel {
				margin-bottom: 1rem;

				& > a {
                    text-transform: uppercase;
                }
			}
		}

		li { display: block; }
	}

	.social-media {
		margin: 3rem 0 -1.5rem;
		max-width: 26rem;

		&__link {
			margin-bottom: 1.5rem;
			padding-right: .75rem;
			width: 50%;
		}

		&__icon {
			@include size(3rem);
			display: block;
			margin-right: .75rem;
			position: relative;
			transition: .25s;

			svg {
				position: absolute;
					top: 50%;
					left: 50%;
				transform: translate(-50%,-50%);
				width: 1.5rem;
			}

			&.facebook {background: #425798;}
			&.instagram {background: #4b719b;}
			&.linkedin {background: #3373a8;}
			&.twitter {background: #4ba8ee;}
            &.wechat {background: #282828;}
			&.youtube {background: #b74030;}
		}

		a {
			line-height: 1.4;

			&:hover,
			&:focus {
				.social-media__icon {transform: scale(1.1);}
			}
		}

		svg path {fill: $white;}
	}

	&__credits {
		border-top: 1px solid $gray;
		font-size: .85rem;
		padding: $spacing 0;

		.legal-footer {
			display: inline-block;

			ul, li {display: inline-block;}

			.legal-menu__item {
				margin-right: .75rem;
			}
		}
	}

	@include media($screen-sm-max, 'max') {
		&__top {
			text-align: center;

			.site-footer__form {margin: 0 auto;}
		}

		&__navigation {
			margin-top: 3rem;

			.footer-menu__submenu {display: none;}

			.footer-menu__item {
				
				&--parent {
					position: relative;

					&.open {
						.footer-menu__submenu {display: block;}
					}
				}
			}

			.submenu-toggle {
				top: -.75rem;

				svg path {fill: $white;}
			}
		}

		&__credits {
			.contain {
				flex-direction: column;
				text-align: center;
			}

			.legal-footer {margin-bottom: $spacing;}
		}
	}
    
    @include media($screen-sm) {
        &__navigation {
            .footer-menu__item {
                padding-right: #{$spacing * 2};
            }
        }
        
        &__top {
            .gform_wrapper {
                flex: 1;
                margin-left: $spacing;
            }
        }
    }

	@include media($screen-md) {
		&__navigation {
            flex: 1;
            
			> ul {
				display: flex;
					justify-content: space-between;
                    gap: 1rem;
                font-size: .875rem;
				margin: $spacing 0;
			}

			.footer-menu__item {
                padding-right: 0;
                
                &.toplevel {
                    padding-right: $spacing;
    
                    &:last-child {padding-right: 0;}
    
                    > a {
						font-weight: bold;
                        margin-bottom: .75rem;
                    }
                }
                
                & > a {
                    font-size: .85rem;
                }
                
                &.toplevel > a {
                    font-size: .85rem;
                }
            }
            
		}
        
        &__top {
            h2 {
                margin-bottom: 0;
            }
        }
	}
    
    @include media($screen-lg) {
		&__navigation {
			.footer-menu__item {
                font-size: 1rem;
                
                &.toplevel {
                    > a {
                        font-size: 1rem;
                    }
                }
                
                & > a {
                    font-size: 1rem;
                }
            }
        }
	}
}
