#location-modal {
    background-color: rgba(0,0,0, 0.85);
    overflow: scroll;
    display: none;
    position: fixed;
    z-index: 2000;
    width: 100%;
    height: 100vh;
    text-align: center;
    justify-content: center;
    align-items: center;
    
    #location-modal-close {
        display: flex;
            justify-content: end;
            align-items: center;

        &:hover {
            cursor: pointer;
        }

        img {
            width: 24px;
            height: 24px;
            margin-left: 6px;
        }
    }

    #location-content {
        max-width: 80%;
        background-color: white;
        padding: 10px 30px 30px 30px;
        margin: 0 auto;

        .location-content__header {
            display: flex;
                align-items: center;
                justify-content: space-between;
            margin-bottom: 1.5rem;

            img {max-width: 8rem;}

            @include media($screen-sm) {
                margin-bottom: 3rem;
            }
        }
    }

    .locations {
        display: flex;
            justify-content: space-evenly;

        @include media($screen-sm-max, 'max') {
            flex-wrap: wrap;

            a {
                margin-bottom: 1rem;
                width: 50%;
            }
        }

        a {
            display: flex;
                flex-direction: column;
                align-items: center;

            img {
                width: 200px;
            }
        }
    }

    img {width: 200px;}

    @include media($screen-sm-max, 'max') {
        display: flex;
        margin: 0 auto;

        #location-content {
            max-height: calc(100% - 2rem);
            max-width: calc(100% - 2rem);
            padding: 16px 16px 32px;

            .h3 {
                font-size: 1.75rem;
                line-height: 1.2;
            }
        }
    }
}
