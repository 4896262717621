.image-cta {

	&__container {
		padding-bottom: $spacing*2;
		padding-top: $spacing*2;
	}

	&__images {
		height: 0;
		padding-bottom: 50%;
		position: relative;
	}

	&__image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain !important;
		height: 0;
		overflow: hidden;
		padding-bottom: 100%;
		position: absolute;
		width: 100%;
		z-index: 0;

		&-1 {animation: imageOne   25s linear 0s infinite both;}
		&-2 {animation: imageTwo   25s linear 0s infinite both;}
		&-3 {animation: imageThree 25s linear 0s infinite both;}
	}

	@include media($screen-sm-max, 'max') {
		position: relative;

		h2, h3 {max-width: 12rem}

		&__images {
			max-width: 15rem;
			position: absolute;
				top: 1rem;
				left: 41%;
			width: 60%;
		}

		&__text {
			img {max-width: 9rem !important;}
		}
	}

	@include media($screen-sm) {
		margin-left: -$spacing;
		margin-right: -$spacing;

		&__container.contain {
			max-width: calc(#{$containSize} + #{$spacing*2});
			padding: #{$spacing} #{$spacing*2};
		}

		&__text {
			padding-right: 3rem;
		}
	}
}


@keyframes imageOne {
	0%		{clip-path: polygon(0 0, 33% 0, 33% 100%, 0% 100%);} // Left 1/3rd
	9.1%	{clip-path: polygon(0 0, 66% 0, 66% 100%, 0% 100%);} // Left 2/3rd
	18.2%	{clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);} // Full
	27.3%	{clip-path: polygon(0 0, 66% 0, 66% 100%, 0% 100%);} // Left 2/3rd
	36.4% 	{clip-path: polygon(0 0, 33% 0, 33% 100%, 0% 100%);} // Left 1/3rd
	45.5% 	{clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);} // Hidden
	54.6%	{clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);} // Hidden
	63.7% 	{clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);} // Hidden
	72.8% 	{clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);} // Hidden
	81.9%	{clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);} // Hidden
	91% 	{clip-path: polygon(0 0, 33% 0, 33% 100%, 0% 100%);} // Left 1/3rd
	100% 	{clip-path: polygon(0 0, 33% 0, 33% 100%, 0% 100%);} // Left 1/3rd
}

@keyframes imageTwo {
	0%		{clip-path: polygon(33% 0, 66% 0, 66% 100%, 33% 100%);} // Center 1/3rd
	9.1%	{clip-path: polygon(66% 0, 66% 0, 66% 100%, 66% 100%);} // Hidden at 2/3rd point
	18.2%	{clip-path: polygon(66% 0, 66% 0, 66% 100%, 66% 100%);} // Hidden at 2/3rd point
	27.3%	{clip-path: polygon(66% 0, 66% 0, 66% 100%, 66% 100%);} // Hidden at 2/3rd point
	36.4% 	{clip-path: polygon(33% 0, 66% 0, 66% 100%, 33% 100%);} // Center 1/3rd
	45.5% 	{clip-path: polygon(0 0, 66% 0, 66% 100%, 0 100%);} // Left 2/3rd
	54.6%	{clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);} // Full
	63.7% 	{clip-path: polygon(0 0, 66% 0, 66% 100%, 0 100%);} // Left 2/3rd
	72.8% 	{clip-path: polygon(0 0, 33% 0, 33% 100%, 0 100%);} // Left 1/3rd
	81.9%	{clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);} // Hidden at left edge
	91% 	{clip-path: polygon(33% 0, 33% 0, 33% 100%, 33% 100%);} // Hidden at left 1/3rd point
	100% 	{clip-path: polygon(33% 0, 66% 0, 66% 100%, 33% 100%);} // Left 2/3rd
}

@keyframes imageThree {
	0%		{clip-path: polygon(66% 0, 100% 0%, 100% 100%, 66% 100%);} // Right 1/3rd
	9.1%	{clip-path: polygon(66% 0, 100% 0%, 100% 100%, 66% 100%);} // Right 1/3rd
	18.2%	{clip-path: polygon(100% 0, 100% 0%, 100% 100%, 100% 100%);} // Hidden at right edge
	27.3%	{clip-path: polygon(66% 0, 100% 0%, 100% 100%, 66% 100%);} // Right 1/3rd
	36.4% 	{clip-path: polygon(66% 0, 100% 0%, 100% 100%, 66% 100%);} // Right 1/3rd
	45.5% 	{clip-path: polygon(66% 0, 100% 0%, 100% 100%, 66% 100%);} // Right 1/3rd
	54.6%	{clip-path: polygon(100% 0, 100% 0%, 100% 100%, 100% 100%);} // Hidden at right edge
	63.7% 	{clip-path: polygon(66% 0, 100% 0%, 100% 100%, 66% 100%);} // Right 1/3rd
	72.8% 	{clip-path: polygon(33% 0, 100% 0%, 100% 100%, 33% 100%);} // Right 2/3rd
	81.9%	{clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%);} // Full
	91% 	{clip-path: polygon(33% 0, 100% 0%, 100% 100%, 33% 100%);} // Right 2/3rd
	100% 	{clip-path: polygon(66% 0, 100% 0%, 100% 100%, 66% 100%);} // Right 1/3rd
}