.solutions-center, .post-feed-manual {

	&__text {
		padding: 1rem 0;
	}

	&__item {

		&:first-child {

			.solutions-center__image, .post-feed-manual__image {
				position: relative;

				&:after {
					@include size(100%);
					background: linear-gradient(34.24deg, rgba(0, 0, 0, 0.77) 0%, rgba(0, 0, 0, 0) 80.76%);
					content: '';
					position: absolute;
						top: 0;
						left: 0;
				}
			}
		}
	}

	&__image {
		height: 0;
		overflow: hidden;
		position: relative;

		img {@include object-fit(cover, center);}
	}

	@include media($screen-sm-max, 'max') {

		&__item {

			.solutions-center__text {
				margin-bottom: 0;				
			}

			&:first-child {
				margin-bottom: $spacing;
				position: relative;

				.solutions-center__image, .post-feed-manual__image {padding-bottom: 85%;}

				.solutions-center__text, .post-feed-manual__text {
					padding: 0;
					position: absolute;
						bottom: $spacing;
						left: $spacing;
					z-index: 2;

					h3 {
						font-size: 1.25rem;
						margin-bottom: 0;

						a {color: white;}
					}
				}
			}

			&:not(:first-child) {
				border-bottom: 1px solid $gray;

				.solutions-center__image, .post-feed-manual__image {display: none;}

				.solutions-center__text, .post-feed-manual__text {
					h3 {
						color: black;
						margin-bottom: 0;
					}

					a {
						display: inline-block;
						padding-right: 3rem;
						position: relative;
					}
				}
			}
		}
	}

	.placeholder {
		@include size(100%);
		background: url('#{$libraryPath}/images/site-logo.png') no-repeat center;
			background-size: 70%;
		position: absolute;
			top: 0;
			left: 0;
	}

	@include media($screen-sm) {

		&__wrapper {
			margin: 0 -#{$spacing/2};
			margin-bottom: -$spacing;
            
            // if 'feature-post' class is present on wrapper, apply styling to
            // first post so that it displays larger and center of first row
            &.feature-post {
                .solutions-center__item, .post-feed-manual__item {
                    &:nth-child(1) {
                        order: 2;
                        position: relative;
                        width: calc(50% - #{$spacing});
    
                        .solutions-center__text, .post-feed-manual__text {
                            max-width: 29rem;
                            position: absolute;
                                bottom: 0;
                                left: 0;
                            width: 100%;
    
                            h3, p, a {color: $white;}
    
                            h3 {font-size: 1.5rem;}
    
                            p {max-width: 20rem;}
                        }
    
                        .solutions-center__image, .post-feed-manual__image {
                            height: 100%;
                            padding-bottom: 0;
                        }
                    }
                    
                    &:nth-child(2) {order: 1;}
                }
            }
		}

		&__item {
			background: white;
			border: 1px solid $gray;
			margin: 0 #{$spacing/2} $spacing;
			transition: 1.5s;
			width: calc(25% - #{$spacing});

			&:nth-child(1) {order: 1;}
			&:nth-child(2) {order: 2;}
			&:nth-child(3) {order: 3;}
			&:nth-child(4) {order: 4;}
			&:nth-child(5) {order: 5;}
			&:nth-child(6) {order: 6;}
			&:nth-child(7) {order: 7;}

			&:hover {
				filter: drop-shadow(0px 0px 8px rgba(0,0,0,.2));

				.solutions-center__image img {transform: translate(-50%,-50%) scale(1.1);}
			}
		}

		&__image {
			filter: drop-shadow(0px 0px 5px rgba(0,0,0,0));
			height: 0;
			overflow: hidden;
			padding-bottom: 75%;
			position: relative;

			img {@include object-fit(cover, center); transition: 3s;}
		}


		&__category {
			background: $navy;
			color: white;
			border-radius: 2px;
			min-width: 7rem;
			padding: .25rem 1rem .25rem .25rem;
			position: absolute;
				top: .75rem;
				left: .75rem;
			z-index: 2;
		}

		&__text {
			padding: $spacing/2;

			h3 {margin-bottom: .5rem;}
		}
	}
}
