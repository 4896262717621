/**
 * Resources Layout
 * DBS>Interactive
 */
.resources {
    display: flex;
        flex-direction: column;
        
    .arrow-link:before {
        right: -.3rem;
    }
    
	.cell {
        display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
        padding: #{$spacing * .667};
	}
    
    &__left {
        padding: $spacing;
        
        h3 {
            color: $charcoal;
            font-size: 50px;
        }
    }
    
    &__left, &__right {
        width: 100%;
    }
    
    &__content {
        display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
        padding: 0 #{$spacing};
        width: 100%;
    }
    
    &__image {
        height: auto;
            
        img {
            height: 85px;
            width: auto;
        }
    }
    
    &__link {
        margin-top: #{$spacing * .667};
        
        a {
            font-size: .875rem;
        }
    }
    
    &__right {
        display: flex;
            justify-content: center;
        width: 100%;
    }
    
    // 30.000em;  //  480px
    @include media($screen-xs) {
        .cell {
            width: calc(50% - .75rem);
        }
    }
    
    // 48.000em;  //  768px
    @include media($screen-sm-max, 'max') {
        &__link a {
            padding-right: 1.75rem;
            
            &:after {
                height: .75rem;
                width: .75rem;
            }
        }
    }
    
    // 48.000em;  //  768px
    @include media($screen-sm) {
        .arrow-link:before {
            right: .15rem;
        }
        
        .cell {
            width: auto;
        }
        
        &__content {
            width: 100%;
        }
        
        &__link {
            text-align: center;
            
            a {
                font-size: 1.125rem;
            }
        }
    }
    
    // 62.000em;  //  992px
    @include media($screen-md) {
        align-items: center;
        flex-direction: row;
            gap: #{$spacing * 3};

        &__content {
            // padding-top: #{$spacing * 3};
            // padding-bottom: #{$spacing * 4};
            align-items: stretch !important;
            gap: #{$spacing};
            height: 100%;
            justify-content: center;
        }
        
        &__left {
            width: 39%;
        }
        
        &__right {
            width: 61%;
        }
        
        .cell {
            justify-content: space-between;
        }

        &.two-up .cell {
            width: calc(50% - #{$spacing / 2});
        }
        
        &.three-up .cell {
            width: calc(33.33% - 1rem);
        }
        
        &__image {
            margin-right: 0;
        }

        &:nth-child(odd) {
            flex-direction: row-reverse;
        }
    }
}
