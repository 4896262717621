/**
 * Form Styles
 * DBS>Interactive
 */

/**
 * GravityForms Resets
 */

.validation_error{
	padding-bottom: 1em;
}

.gform_body {

	ul { list-style: none; padding: 0; }

	.gfield_label,
	label, legend {
		font-weight: bold;
	}
}

.gfield {
	margin-bottom: 1em;
}

.gfield_list_icons {
	padding: .5em;
}

// Hide validation honeypots
.gform_validation_container {
	display: none !important;
}

.gf_progressbar_wrapper {
	display: flex;
	justify-content: space-between;
}

.gform_anchor,
#gf_form_focus {
	&:focus {box-shadow: none;}
}

/**
 * Add-clear plugin resets
 */

.add-clear-span {
	display: block;
	position: relative;

	// The 'x' link
	& > .add-clear-button {
		display: none !important;
		top: .5em;
		right: .5em;
	}
}

fieldset,
.hidden_label,
.hide-label { 
	> label.gfield_label_before_complex,
    legend.gfield_label_before_complex {
        display: none;
    }

	> label, legend {
		@include size(1px);
		border    : 0;
		clip      : rect(0 0 0 0);
		margin    : -1px;
		overflow  : hidden;
		padding   : 0;
		position  : absolute;
	}
}


/**
 * Input Field Styles
 */

textarea {
	min-height: 5em;
}

input[type=radio] {
	margin-right: .5em;
}

select {
	font-weight: bold;
	min-width: 25%;
	margin: 0;
}

// Text and text-like input fields
textarea,
input[type=text]:not([id=search-input]),
input[type=email],
input[type=date],
input[type=number],
input[type=time],
input[type=week],
input[type=month],
input[type=tel],
input[type=search],
input[type=url],
.gfield select {
	display: block;
	border: 1px solid $gray;
	margin: 0;
	max-width: 100%;
	padding: 1.25rem .75rem;
	width: 100%;
}

fieldset {
    border: none;
	margin-bottom: $spacing*2;
    padding: 0 !important;
}

/**
 * Complex field arrangements
 */
.ginput_complex {
	display: flex;
		flex-direction: column;
		justify-content: space-between;

	@include media($screen-sm-max, 'max') {
		span:nth-of-type(1) {margin-bottom: 1rem;}
	}

	@include media($screen-sm){
		flex-direction: row;
		// Field wrappers in the group
		span {
			display: inline-block;
			width: 50%;

			// Add margin to the second field
			&:nth-of-type(2n) {
				margin-left: 1em;
			}
		}
	}
}

@include media($screen-sm) {
	.gform_fields {
		display: flex;
			flex-wrap: wrap;
			align-items: start;

        .gfield:not(.half, .third, .two-thirds) {width: 100%;}
		
		.half {
			width: calc(50% - .5rem);
			
			&.second {margin-left: 1rem;}
		}
        
        .third {
            width: calc(33.33% - .5rem);

            & + .third {margin-left: .75rem;}
        }
        
        .two-thirds {
            margin-left: .75rem;
            width: calc(66.67% - .25rem);
        }
	}
    
    .ginput_left {
        width: calc(50% - .5rem) !important;
    }
    
    .ginput_right {
        margin-left: 1rem !important;
        width: calc(50% - .5rem) !important;
    }
}

.ginput_address_country {
	label {
		@include size(1px);
		border    : 0;
		clip      : rect(0 0 0 0);
		margin    : -1px;
		overflow  : hidden;
		padding   : 0;
		position  : absolute;
	}
}

/**
 * Remove IE's clear so we don't conflict with the add-clear button functionality 2017-09-12
 */
input[type=text]::-ms-clear {
	display: none;
}


/**
 * Validation and error message styling
 */

.gfield_error label,
.gfield_error legend,
.validation_message {
	color: red;
}

.gfield_error select {color: $red;}

.gfield_error input,
.gfield_error textarea,
.gfield_error select {
	border-color: red;
}

.gfield_visibility_hidden {
    content-visibility: hidden;
    display: none;
}

/**
 * Loader animation
 */

.gform_ajax_spinner {
	@include size(1em);
	animation: spinner_animation 1s infinite ease;
	border-radius: 50%;
	font-size: 5px;
	margin: 0 5em;
	transform: translateZ(0);
}

$spinner_color: $black;

@-webkit-keyframes spinner_animation {
	0%,
	100% {
		box-shadow: 0em -2.6em 0em 0em $spinner_color, 1.8em -1.8em 0 0em rgba($spinner_color, 0.2), 2.5em 0em 0 0em rgba($spinner_color, 0.2), 1.75em 1.75em 0 0em rgba($spinner_color, 0.2), 0em 2.5em 0 0em rgba($spinner_color, 0.2), -1.8em 1.8em 0 0em rgba($spinner_color, 0.2), -2.6em 0em 0 0em rgba($spinner_color, 0.5), -1.8em -1.8em 0 0em rgba($spinner_color, 0.7);
	}
	12.5% {
		box-shadow: 0em -2.6em 0em 0em rgba($spinner_color, 0.7), 1.8em -1.8em 0 0em $spinner_color, 2.5em 0em 0 0em rgba($spinner_color, 0.2), 1.75em 1.75em 0 0em rgba($spinner_color, 0.2), 0em 2.5em 0 0em rgba($spinner_color, 0.2), -1.8em 1.8em 0 0em rgba($spinner_color, 0.2), -2.6em 0em 0 0em rgba($spinner_color, 0.2), -1.8em -1.8em 0 0em rgba($spinner_color, 0.5);
	}
	25% {
		box-shadow: 0em -2.6em 0em 0em rgba($spinner_color, 0.5), 1.8em -1.8em 0 0em rgba($spinner_color, 0.7), 2.5em 0em 0 0em $spinner_color, 1.75em 1.75em 0 0em rgba($spinner_color, 0.2), 0em 2.5em 0 0em rgba($spinner_color, 0.2), -1.8em 1.8em 0 0em rgba($spinner_color, 0.2), -2.6em 0em 0 0em rgba($spinner_color, 0.2), -1.8em -1.8em 0 0em rgba($spinner_color, 0.2);
	}
	37.5% {
		box-shadow: 0em -2.6em 0em 0em rgba($spinner_color, 0.2), 1.8em -1.8em 0 0em rgba($spinner_color, 0.5), 2.5em 0em 0 0em rgba($spinner_color, 0.7), 1.75em 1.75em 0 0em $spinner_color, 0em 2.5em 0 0em rgba($spinner_color, 0.2), -1.8em 1.8em 0 0em rgba($spinner_color, 0.2), -2.6em 0em 0 0em rgba($spinner_color, 0.2), -1.8em -1.8em 0 0em rgba($spinner_color, 0.2);
	}
	50% {
		box-shadow: 0em -2.6em 0em 0em rgba($spinner_color, 0.2), 1.8em -1.8em 0 0em rgba($spinner_color, 0.2), 2.5em 0em 0 0em rgba($spinner_color, 0.5), 1.75em 1.75em 0 0em rgba($spinner_color, 0.7), 0em 2.5em 0 0em $spinner_color, -1.8em 1.8em 0 0em rgba($spinner_color, 0.2), -2.6em 0em 0 0em rgba($spinner_color, 0.2), -1.8em -1.8em 0 0em rgba($spinner_color, 0.2);
	}
	62.5% {
		box-shadow: 0em -2.6em 0em 0em rgba($spinner_color, 0.2), 1.8em -1.8em 0 0em rgba($spinner_color, 0.2), 2.5em 0em 0 0em rgba($spinner_color, 0.2), 1.75em 1.75em 0 0em rgba($spinner_color, 0.5), 0em 2.5em 0 0em rgba($spinner_color, 0.7), -1.8em 1.8em 0 0em $spinner_color, -2.6em 0em 0 0em rgba($spinner_color, 0.2), -1.8em -1.8em 0 0em rgba($spinner_color, 0.2);
	}
	75% {
		box-shadow: 0em -2.6em 0em 0em rgba($spinner_color, 0.2), 1.8em -1.8em 0 0em rgba($spinner_color, 0.2), 2.5em 0em 0 0em rgba($spinner_color, 0.2), 1.75em 1.75em 0 0em rgba($spinner_color, 0.2), 0em 2.5em 0 0em rgba($spinner_color, 0.5), -1.8em 1.8em 0 0em rgba($spinner_color, 0.7), -2.6em 0em 0 0em $spinner_color, -1.8em -1.8em 0 0em rgba($spinner_color, 0.2);
	}
	87.5% {
		box-shadow: 0em -2.6em 0em 0em rgba($spinner_color, 0.2), 1.8em -1.8em 0 0em rgba($spinner_color, 0.2), 2.5em 0em 0 0em rgba($spinner_color, 0.2), 1.75em 1.75em 0 0em rgba($spinner_color, 0.2), 0em 2.5em 0 0em rgba($spinner_color, 0.2), -1.8em 1.8em 0 0em rgba($spinner_color, 0.5), -2.6em 0em 0 0em rgba($spinner_color, 0.7), -1.8em -1.8em 0 0em $spinner_color;
	}
}

@keyframes spinner_animation {
	0%,
	100% {
		box-shadow: 0em -2.6em 0em 0em $spinner_color, 1.8em -1.8em 0 0em rgba($spinner_color, 0.2), 2.5em 0em 0 0em rgba($spinner_color, 0.2), 1.75em 1.75em 0 0em rgba($spinner_color, 0.2), 0em 2.5em 0 0em rgba($spinner_color, 0.2), -1.8em 1.8em 0 0em rgba($spinner_color, 0.2), -2.6em 0em 0 0em rgba($spinner_color, 0.5), -1.8em -1.8em 0 0em rgba($spinner_color, 0.7);
	}
	12.5% {
		box-shadow: 0em -2.6em 0em 0em rgba($spinner_color, 0.7), 1.8em -1.8em 0 0em $spinner_color, 2.5em 0em 0 0em rgba($spinner_color, 0.2), 1.75em 1.75em 0 0em rgba($spinner_color, 0.2), 0em 2.5em 0 0em rgba($spinner_color, 0.2), -1.8em 1.8em 0 0em rgba($spinner_color, 0.2), -2.6em 0em 0 0em rgba($spinner_color, 0.2), -1.8em -1.8em 0 0em rgba($spinner_color, 0.5);
	}
	25% {
		box-shadow: 0em -2.6em 0em 0em rgba($spinner_color, 0.5), 1.8em -1.8em 0 0em rgba($spinner_color, 0.7), 2.5em 0em 0 0em $spinner_color, 1.75em 1.75em 0 0em rgba($spinner_color, 0.2), 0em 2.5em 0 0em rgba($spinner_color, 0.2), -1.8em 1.8em 0 0em rgba($spinner_color, 0.2), -2.6em 0em 0 0em rgba($spinner_color, 0.2), -1.8em -1.8em 0 0em rgba($spinner_color, 0.2);
	}
	37.5% {
		box-shadow: 0em -2.6em 0em 0em rgba($spinner_color, 0.2), 1.8em -1.8em 0 0em rgba($spinner_color, 0.5), 2.5em 0em 0 0em rgba($spinner_color, 0.7), 1.75em 1.75em 0 0em $spinner_color, 0em 2.5em 0 0em rgba($spinner_color, 0.2), -1.8em 1.8em 0 0em rgba($spinner_color, 0.2), -2.6em 0em 0 0em rgba($spinner_color, 0.2), -1.8em -1.8em 0 0em rgba($spinner_color, 0.2);
	}
	50% {
		box-shadow: 0em -2.6em 0em 0em rgba($spinner_color, 0.2), 1.8em -1.8em 0 0em rgba($spinner_color, 0.2), 2.5em 0em 0 0em rgba($spinner_color, 0.5), 1.75em 1.75em 0 0em rgba($spinner_color, 0.7), 0em 2.5em 0 0em $spinner_color, -1.8em 1.8em 0 0em rgba($spinner_color, 0.2), -2.6em 0em 0 0em rgba($spinner_color, 0.2), -1.8em -1.8em 0 0em rgba($spinner_color, 0.2);
	}
	62.5% {
		box-shadow: 0em -2.6em 0em 0em rgba($spinner_color, 0.2), 1.8em -1.8em 0 0em rgba($spinner_color, 0.2), 2.5em 0em 0 0em rgba($spinner_color, 0.2), 1.75em 1.75em 0 0em rgba($spinner_color, 0.5), 0em 2.5em 0 0em rgba($spinner_color, 0.7), -1.8em 1.8em 0 0em $spinner_color, -2.6em 0em 0 0em rgba($spinner_color, 0.2), -1.8em -1.8em 0 0em rgba($spinner_color, 0.2);
	}
	75% {
		box-shadow: 0em -2.6em 0em 0em rgba($spinner_color, 0.2), 1.8em -1.8em 0 0em rgba($spinner_color, 0.2), 2.5em 0em 0 0em rgba($spinner_color, 0.2), 1.75em 1.75em 0 0em rgba($spinner_color, 0.2), 0em 2.5em 0 0em rgba($spinner_color, 0.5), -1.8em 1.8em 0 0em rgba($spinner_color, 0.7), -2.6em 0em 0 0em $spinner_color, -1.8em -1.8em 0 0em rgba($spinner_color, 0.2);
	}
	87.5% {
		box-shadow: 0em -2.6em 0em 0em rgba($spinner_color, 0.2), 1.8em -1.8em 0 0em rgba($spinner_color, 0.2), 2.5em 0em 0 0em rgba($spinner_color, 0.2), 1.75em 1.75em 0 0em rgba($spinner_color, 0.2), 0em 2.5em 0 0em rgba($spinner_color, 0.2), -1.8em 1.8em 0 0em rgba($spinner_color, 0.5), -2.6em 0em 0 0em rgba($spinner_color, 0.7), -1.8em -1.8em 0 0em $spinner_color;
	}
}

/**
 * Animated Form Label
 *
 * Class must be added in the GForms admin
 */
.animate-label {
	position: relative;

	.ginput_complex {
		> span {
			@include size(100%);
			display: inline-block;
			position: relative;

			@at-root .gform_validation_error .gfield_error.animate-label:not(.text-area),
			&.active {
				label, legend {transform: translateY(-2.1rem) scale(.7);}
			}

			// @at-root .gform_validation_error .gfield.animate-label.gfield_error {
			// 	> .gfield_label {
			// 		transform: translateY(-3rem) scale(.7);
			// 	}
			// }
		}

		&.gfield_error {
			label {transform: translateY(-2.1rem) scale(.7);}
		}
	}
    
    .gfield_label_before_complex {
        display: none;
    }

	label, legend {
		padding: 0 .25rem;
		position: absolute;
			top: 1.9rem;
			left: .6rem;
		transition: .2s ease-in-out;
		transform: translateY(-50%);
		transform-origin: left;
	}

	&.select-field {
		select {
			font-weight: 700;
			padding: 1.5rem .75rem;
		}
	}

	&.text-area {
		label {
			top: .5rem;
			transform: none;
		}

		textarea {
			max-height: 12rem;
			padding-top: 3rem;
		}
        
        &.gfield_error label, &.gfield_error legend {transform: none !important;}
	}

	@at-root .gform_validation_error .gfield_error.animate-label:not(.text-area),
	&.active {
		label, legend {transform: translateY(-2.1rem) scale(.7);}
	}
}

.landing-wide-form .input-group > .form-control {
    font-size: 1rem;
}

.gform_submission_error {
	color: red;
    font-family: $font-ubuntu;
    font-size: 1.125rem;
    margin-bottom: .5rem !important;
    text-transform: none;
}

.gfield_validation_message {
    margin-top: .5rem;
}