/**
 * Product Grid Layout
 * DBS>Interactive
 */
.product-grid {

	.cell {
		padding-top: 1em;
        text-align: center;
		width: 100%;
        
        img {
            height: 150px;
            object-fit: contain;
            width: auto;
        }
        
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0,0,0,.031);
            height: 98%;
            width: 99%;
            pointer-events: none;
        }

		@include media ($screen-sm) {
			width: 50%;
		}
	}

    @include media ($screen-md) {
        &.three-up .cell {
            width: calc(33.33% - #{$spacing});
        }

        &.four-up .cell {
            width: calc(25% - #{$spacing});
        }
        
        &.five-up .cell {
            width: calc(20% - #{$spacing});
        }
    }
    
    &__content {
        gap: $spacing;
    }
    
    &__heading {
        margin-bottom: #{$spacing * 2};
    }
    
    &__product {
        margin-bottom: $spacing;
    }
    
    &.excerpt-listlike {
        .product-grid__excerpt {
            font-size: .9rem;
        }
    }
}
