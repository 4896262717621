.wechat-modal {
    max-width: 29rem;
    opacity: 0;
    position: fixed;
        top: 50%;
        left: 50%;
    transform: translate(-50%,-50%);
    transition: opacity .5s, visibility 0s .5s;
    visibility: hidden;
    width: calc(100% - 3rem);
    z-index: 2000;
    
    &:after {
        @include size(100vw, 100vh);
        background: rgba($black, .85);
        content: '';
        position: absolute;
            top: 50%;
            left: 50%;
        transform: translate(-50%,-50%);
        z-index: -1;
    }
    
    &.active {
        opacity: 1;
        transition: opacity .5s, visibility 0s 0s;
        visibility: visible;
    }
    
    &__background {
        background-color: #F7F7F7;
        padding: $spacing;
    }
    
    &__close {
        @include size(3rem);
        background: url('../icons/src/close-symbol.svg') no-repeat center;
            background-size: 1.5rem;
        border: none;
        position: absolute;
            top: 0;
            right: 0;
    }
    
    img {
        margin-top: $spacing;
    }
}